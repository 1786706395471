#primary-nav .level-1 {
  width: 18%;
  padding: 1em 0px;
  &:nth-child(3){
  	width: 28%;
  }
  font-size: 14px;
  @include breakpoint($lg){
    font-size: 16px;
  }
}

.block-menu-block .content {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 15px;
    -moz-column-gap: 15px;
    column-gap: 15px;
}

.brand__lockup img{
    max-height: 84px;
}

h2.titletag{
    display: none;
    @include breakpoint($sm){
        display: block;
    }
    width: 100%;
    text-align: center;
    margin-top: 0;
    line-height: 1.5;
    border-bottom: 5px solid #ddd;
    padding: 11.5px 0;
    a{
        color: $wcm-bright-orange;
        font-family: '1898Sans-Regular', sans-seriff;
        font-size: 38px;
        &:after{
            display: none;
        }
    }
}

// .slide-text{
//     background-color: #000;
//     p a{
//         color: $wcm-dark-orange;
//         font-weight: bold;
//     }
//     h2{
//         padding-top: 10px;
//         a:after{
//             line-height: 2;
//         }
//     }
// }

.title-bar {
  .site-title {
    @include breakpoint($sm){
      font-size: 26px;
    }
    @include breakpoint($md){
      font-size: 38px;
    }
    @include breakpoint($lg){
      font-size: 46px;
    }
  }
  a .home-icon {
    @include breakpoint($sm){
      font-size: 20px;
    }
    @include breakpoint($md){
      font-size: 26px;
    }
    @include breakpoint($lg){
      font-size: 30px;
    }
  }
}
