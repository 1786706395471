/**
 * Example Stub for creating WCMC Subthemes
 *
 */
/**
 * This file is used to customize the default Bootstrap core variables.
 * A list of options can be found here:
 * https://github.com/twbs/bootstrap-sass/blob/3.2-stable/assets/stylesheets/bootstrap/_variables.scss
 */
/* =WCM Colors and Fonts
----------------------------------------------------------*/
/* !WARNING!
When changing the colors below also change them in:
profiles/wcmc/modules/custom/panopoly_override/features/panopoly_wysiwyg.inc:53,
inside the panopoly_override_wysiwyg_editor_settings_alter() function.
JIRA Ticket: WEBENG-843
*/
/* =Scaffolding
----------------------------------------------------------*/
/* =Typography
----------------------------------------------------------*/
/* =Grid System
Padding between columns. Gets divided in half for the left and right.
----------------------------------------------------------*/
/* =Buttons
Define text, background and border color.
----------------------------------------------------------*/
/* =Forms
----------------------------------------------------------*/
/* =Pagination
----------------------------------------------------------*/
/**
 * Custom Mixins
 *
 */
/* =Breakpoint Utility
// https://medium.com/developing-with-sass/refactoring-my-simple-sass-breakpoint-mixin-71205dd6badd
----------------------------------------------------------*/
/* =Container Wrap
This is used when you can't add the class="container" markup,
just use this mixin on a wrapping element instead.
----------------------------------------------------------*/
/* =Fontello Icon Font
----------------------------------------------------------*/
/* =Dead Center within parent
----------------------------------------------------------*/
/* =Vertically Center
----------------------------------------------------------*/
/* =Button SubNav Style
----------------------------------------------------------*/
/* =Site-specific styles
----------------------------------------------------------*/
#primary-nav .level-1 {
  width: 18%;
  padding: 1em 0px;
  font-size: 14px; }
  #primary-nav .level-1:nth-child(3) {
    width: 28%; }
  @media screen and (min-width: 1200px) {
    #primary-nav .level-1 {
      font-size: 16px; } }

.block-menu-block .content {
  -webkit-column-count: 3;
  -moz-column-count: 3;
  column-count: 3;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px; }

.brand__lockup img {
  max-height: 84px; }

h2.titletag {
  display: none;
  width: 100%;
  text-align: center;
  margin-top: 0;
  line-height: 1.5;
  border-bottom: 5px solid #ddd;
  padding: 11.5px 0; }
  @media screen and (min-width: 768px) {
    h2.titletag {
      display: block; } }
  h2.titletag a {
    color: #e7751d;
    font-family: '1898Sans-Regular', sans-seriff;
    font-size: 38px; }
    h2.titletag a:after {
      display: none; }

@media screen and (min-width: 768px) {
  .title-bar .site-title {
    font-size: 26px; } }

@media screen and (min-width: 992px) {
  .title-bar .site-title {
    font-size: 38px; } }

@media screen and (min-width: 1200px) {
  .title-bar .site-title {
    font-size: 46px; } }

@media screen and (min-width: 768px) {
  .title-bar a .home-icon {
    font-size: 20px; } }

@media screen and (min-width: 992px) {
  .title-bar a .home-icon {
    font-size: 26px; } }

@media screen and (min-width: 1200px) {
  .title-bar a .home-icon {
    font-size: 30px; } }
